@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700;900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --malibu: #5c9dff;
    --scorpion: #5e5e5e;
    @apply bg-woodsmoke;
  }

  html {
    font-family: "Inter", sans-serif;
  }

  html, body, #root {
    height: 100%;
    width: 100%;
  }

  #root {
    display: flex;
    flex-direction: column;
  }

  input[type="range"]::-webkit-slider-thumb {
    @apply h-2 w-2 appearance-none rounded-full border-none bg-malibu;
  }
}
